.dataTable tfoot {
  display: table-header-group;
}

.table-bordered> thead> tr> th,
.table-bordered> tbody> tr> th,
.table-bordered> tfoot> tr> th,
.table-bordered> thead> tr> td,
.table-bordered> tbody> tr> td,
.table-bordered> tfoot> tr> td {
  border: @light-border;
}

.table.table-bordered {
  thead> tr> th {
    border: @light-border;
  }
}

.table,
.table.table-striped,
.table.table-bordered {
  tfoot, thead, tbody {
    > tr.no-border-bottom > th,
    > tr.no-border-bottom > td {
      border-bottom: 0 !important;
    }
    > tr.no-border-top > th,
    > tr.no-border-top > td {
      border-top: 0 !important;
    }
  }
}

.table-center {
  tfoot, thead, tbody {
    > tr > th,
    > tr > td {
      text-align: center;
    }
  }
}

.table,
.table.table-striped {
  background: #FFF;
  border: @light-border;
  &.dataTable {
    thead {
      &:first-child> tr:first-child> th {
        border: 0 !important;
      }
    }
  }
  thead {
    &:first-child> tr:first-child> th {
      color: @bg-dark;
      font-size: 0.95rem;
      padding: 8px 10px;
      padding-bottom: 5px;
    }
  }
  thead> tr> th {
    text-transform: uppercase;
    padding: 8px 10px;
    border-bottom: @light-border;
    color: @bg-dark;
  }
  &.dataTable> tfoot> tr> th {
    border: none;
    padding: 8px;
    padding-top: 0;
    text-align: center;
    vertical-align: middle;
  }
  > tbody> tr,
  > tbody> tr:nth-of-type(odd) {
    &:hover {
      background-color: transparent;
      > td {
        background-color: rgba(243, 243, 243, 0.2);
        // .btn {
          //   opacity: 1;
        // }
        // > .btn:only-of-type {
          //   display: block;
          //   width: 100%;
          //   text-align: center;
        // }
      }
    }
    > td {
      padding: 8px 10px;
      font-weight: 500;
      color: @blue-gray;
      vertical-align: middle;
      border-top: @light-border;
      // .btn {
        //   transition: opacity ease 0.2s;
        //   opacity: 0;
      // }
      // > .btn:only-of-type {
        //   display: block;
        //   width: 100%;
        //   text-align: center;
      // }
      .btn:not(.modal .btn) {
        padding-top: 2px !important;
        padding-bottom: 2px !important;
      }
      .btn-lg.text-succes,
      .btn-lg.text-danger {
        font-size: inherit;
      }
    }
  }
}

.table.table-bordered {
  thead {
    &:first-child> tr:first-child> th {
      border: @light-border !important;
    }
  }
}

.dt-buttons,
.dataTables_filter {
  float: left;
}

.dataTables_length {
  float: right;
}

div.dataTables_wrapper div.dataTables_length label {
  font-size: 1.05rem;
  color: @bg-dark;
  font-weight: 500;
}

div.dataTables_wrapper div.dataTables_filter input,
.dataTables_length input {
  margin-left: 0 !important;
}

.dataTables_info,
.dataTables_paginate {
  float: right;
}

.dataTables_info {
  color: @blue-gray;
  font-weight: 500;
  margin-right: 15px;
  font-size: 1rem;
}

table.dataTable.dtr-inline.collapsed> tbody> tr[role="row"]> td:first-child,
table.dataTable.dtr-inline.collapsed> tbody> tr[role="row"]> th:first-child {
  padding-left: 35px;
}

table.dataTable> tbody> tr.child> td.child {
  border-left: solid 2px @base-color;
}

table.dataTable.dtr-inline.collapsed> tbody> tr[role="row"]> td:first-child:before,
table.dataTable.dtr-inline.collapsed> tbody> tr[role="row"]> th:first-child:before {
  left: 7.5px;
  width: 20px;
  height: 20px;
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 0.5px @light-gray;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Ionicons";
  content: "\f218";
  color: @bg-dark;
  box-shadow: none;
  line-height: 20px;
  font-size: 1rem;
  text-align: center;
}

table.dataTable.dtr-inline.collapsed> tbody> tr.parent> td:first-child:before,
table.dataTable.dtr-inline.collapsed> tbody> tr.parent> th:first-child:before {
  color: #FFF;
  background-color: @base-color;
  content: "\f209";
  border: solid 0.5px transparent;
}

table.dataTable> tbody> tr.child span.dtr-title {
  text-transform: uppercase;
}

table.dataTable> tbody> tr.child ul.dtr-details> li {
  border-bottom: 0;
  & {
    padding-left: 1.3em;
  }
  &:before {
    content: "\f125";
    font-family: "Ionicons";
    display: inline-block;
    margin-left: -1.3em;
    width: 1.3em;
    font-size: 0.8rem;
  }
}

table.dataTable thead th.sorting_asc:after,
table.dataTable thead th.sorting_desc:after {
  color: @bg-dark;
}

table.dataTable thead th.sorting:after,
table.dataTable thead th.sortingc:after {
  color: @bg-dark-hover;
}

.table-striped> tbody> tr:nth-of-type(odd) {
  background-color: transparent;
}

.table-orange> thead> tr> th {
  background-color: #fb8b34;
  color: white !important;
  text-align: center;
}

.table-blue> thead> tr> th {
  background-color: #0051ab;
  color: white !important;
  text-align: center;
}

.td_subgrupo {
  background-color: #848484 !important;
  color: white !important;
}

.table-cell-list {
  padding-left: 15px;
  margin: 0;
}

.subtotal-grupo {
  font-weight: bold !important;
  color: black !important;
}

.subtotal-obra {
  background-color: #ffe1b1 !important;
}

.table.dataTable,
table.dataTable {
  thead {
    &:first-child> tr:first-child> th {
      padding-right: 20px;
    }
  }
}

.table.dataTable,
table.dataTable {
  textarea.form-control {
    &[readonly],
    &[disabled] {
      background-color: #fff;
      resize: vertical;
      cursor: default;
      min-height: 60px;
      width: 100%;
      &:hover {
        border-color: #d2d6de !important;
      }
    }
  }
}
