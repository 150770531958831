.table-135 th {
  text-align: center;
  min-width: 135px;
  max-width: 135px;
}

.table-135 td {
  text-align: center;
  min-width: 135px;
  max-width: 135px;
}

.cell-insumo {
  min-width: 170px !important;
  max-width: 170px !important;
}

.cell-evolucao-fisica {
  min-width: 78px !important;
  max-width: 78px !important;
}

.cell-obs {
  //min-width: 95px !important;
  max-width: 95px !important;
}

.cell-justificativa {
  min-width: 107px !important;
  max-width: 107px !important;
}

.iCheck-helper {
  position: initial !important;
}

.one_scroll::-webkit-scrollbar {
  display: none;
}

.tr_auxiliar th {
  padding: 0 !important;
}

.table > tbody > tr:hover, .gerencial-table .table>tbody>tr:hover, .table>tbody>tr:nth-of-type(odd):hover {
    //background-color: #8c3333;
    //color: #FFF;
}
