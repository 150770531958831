/*
 * Skin: Yellow
 * ------------
 */
@import "../bootstrap/mixins.less";
@import "../bootstrap/variables.less";
@import "../admin-lte/variables.less";
@import "../admin-lte/mixins.less";

.main-header {
  .navbar {
    .navbar-variant(@base-color; #fff);
    .sidebar-toggle {
      color: #fff;
      &:hover {
        background-color: darken(@base-color, 5%);
      }
    }
    @media (max-width: @screen-header-collapse) {
      .dropdown-menu {
        li {
          &.divider {
            background-color: rgba(255, 255, 255, 0.1);
          }medicoes
          a:not(.btn) {
            color: #fff;
            &:hover {
              background: darken(@base-color, 5%);
            }
          }
        }
      }
    }
  }
  //Logo
  .logo {
    .logo-variant(darken(@base-color, 5%));
  }

  li.user-header {
    background-color: @base-color;
  }
}

//Content Header
.content-header {
  background: transparent;
}

//Create the sidebar skin
.skin-dark-sidebar(@base-color);
